.uppy-DragDrop-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 7px;
  background-color: #fff;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  max-width: 100%;
}
.uppy-DragDrop-container:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.4);
}
.uppy-DragDrop-container::-moz-focus-inner {
  border: 0;
}

.uppy-DragDrop-inner {
  margin: 0;
  text-align: center;
  padding: 80px 20px;
  line-height: 1.4;
}

.uppy-DragDrop-arrow {
  width: 60px;
  height: 60px;
  fill: #e0e0e0;
  margin-bottom: 17px;
}

.uppy-DragDrop--isDragDropSupported {
  border: 2px dashed #adadad;
}

.uppy-DragDrop--isDraggingOver {
  border: 2px dashed #2275d7;
  background: #eaeaea;
}
.uppy-DragDrop--isDraggingOver .uppy-DragDrop-arrow {
  fill: #939393;
}

.uppy-DragDrop-label {
  display: block;
  font-size: 1.15em;
  margin-bottom: 5px;
}

.uppy-DragDrop-browse {
  color: #2275d7;
  cursor: pointer;
}

.uppy-DragDrop-note {
  font-size: 1em;
  color: #adadad;
}